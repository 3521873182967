<script setup lang="ts">
export interface Props {
    debounceInterval?: number;
    formatter?: (input: number) => string;
    interval?: number;
    noPulse?: boolean;
    pulseDuration?: number;
    value: number;
}

const props = withDefaults(defineProps<Props>(), {
    debounceInterval: 300,
    formatter: undefined,
    interval: 15,
    noPulse: false,
    pulseDuration: 700,
});
const emit = defineEmits<{
    'plural-form-change': [value: { isSingular: boolean }];
}>();

const display = ref(props.value);
const intervalId = ref(0);
const timeoutId = ref(0);
const animating = ref(false); // toggled to trigger animation
const animationDuration = computed(() => `${props.pulseDuration}ms`);

const triggerAnimation = () => {
    if (props.noPulse) return;

    animating.value = true;

    timeoutId.value = window.setTimeout(() => {
        animating.value = false;
    }, props.pulseDuration);
};

const ANIMATION_CHANGE_RATE = 5; // smaller is faster
const tweenDisplayValue = useDebounceFn(() => {
    window.clearInterval(intervalId.value);

    if (props.value === display.value) return;

    intervalId.value = window.setInterval(() => {
        if (Math.floor(display.value) !== Math.floor(props.value)) {
            let change = (props.value - display.value) / ANIMATION_CHANGE_RATE;
            change = change >= 0 ? Math.ceil(change) : Math.floor(change);
            display.value = display.value + change;
        } else {
            display.value = props.value;
            triggerAnimation();
            window.clearInterval(intervalId.value);
        }
    }, props.interval);
}, props.debounceInterval);

const formattedDisplay = computed(() => {
    if (!props.formatter) return display.value;
    return props.formatter(display.value);
});

watch(
    () => props.value,
    () => {
        tweenDisplayValue();
    }
);

watch(
    () => display.value,
    (newValue, previousValue) => {
        // only fires when switching to or from 1
        if (newValue === 1 && previousValue !== 1) {
            emit('plural-form-change', { isSingular: true });
        } else if (previousValue === 1 && newValue !== 1) {
            emit('plural-form-change', { isSingular: false });
        }
    }
);

onBeforeUnmount(() => {
    if (intervalId.value) {
        window.clearInterval(intervalId.value);
    }
    if (timeoutId.value) {
        window.clearTimeout(timeoutId.value);
    }
});
</script>

<template>
    <span
        :class="{
            'animated-number': true,
            'single-pulse': animating,
        }"
        :style="{ animationDuration }"
    >
        {{ formattedDisplay }}
    </span>
</template>

<style lang="postcss" scoped>
.animated-number {
    display: inline-block;
}

.single-pulse {
    /* animation-duration is dynamically set based on prop */
    animation-name: single-pulse;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(0, 0.73, 1, 0.62);
}

@keyframes single-pulse {
    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2, 1.4);
    }
}
</style>
